<template>
  <div>
    <div class="text-center" v-if="!dataLoaded">
      <v-progress-circular class="mt-6" :size="50" color="primary" indeterminate />
    </div>

    <div class="mx-4" v-else>
      <PageTitle title="Отчеты" />

      <v-row class="my-0">
        <v-col cols="12" class="pt-0 pb-5">
          <h4 class="department">{{ departmentName }}</h4>
        </v-col>
      </v-row>

      <v-row class="my-0">
        <v-col cols="12" class="pt-0">
          <v-card class="base-card pa-5 mt-1" ref="paramsBlock">
            <v-menu offset-y v-model="reportBtn">
              <template #activator="{ on }">
                <v-btn
                  :disabled="disableReportBtn"
                  v-on="on"
                  text
                  color="accent"
                  class="print-btn px-1"
                  title="Сформировать отчет"
                  :loading="loading"
                  :ripple="false"
                >
                  <v-icon left class="file-icon mr-0" size="20">mdi-file-chart-outline</v-icon>
                  <span class="ml-2">Сформировать отчет</span>
                  <v-icon right class="chevron-icon ml-1">
                    {{ reportBtn ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item dense @click="getReport('curator')">
                  <v-list-item-title class="list-text">
                    Отчет по кураторам
                  </v-list-item-title>
                </v-list-item>

                <v-list-item dense @click="getReport('teacher')">
                  <v-list-item-title class="list-text">
                    Отчет по педагогической нагрузке
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <div class="inputs">
              <div class="dates mt-4">
                <div class="date">
                  <DatePicker
                    :date="fromDate"
                    label="Дата c"
                    @setDate="fromDate = $event"
                  />
                </div>

                <div class="date ml-5">
                  <DatePicker
                    :date="toDate"
                    label="Дата по"
                    @setDate="toDate = $event"
                  />
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { reportApi, workloadApi } from '@/api'
import { mapGetters } from 'vuex'
import download from '@/plugins/download'
import PageTitle from '@/components/ui/Title'
import ReportTable from '@/components/management/reports/Table'
import DatePicker from '@/components/ui/DatePicker'

export default {
  name: 'DepartmentReports',

  metaInfo: {
    title: 'Отчеты'
  },

  components: {
    PageTitle,
    ReportTable,
    DatePicker
  },

  async created() {
    await this.$store.dispatch('user/getCurrent')
    await this.$store.dispatch('department/checkDepartmentInLocalStorage')
    this.dataLoaded = true
  },

  mounted() {
    this.hideHtmlOverflow()
    window.addEventListener("resize", this.setParamsBlockHeight)
  },

  destroyed() {
    this.showHtmlOverflow()
    window.removeEventListener("resize", this.setParamsBlockHeight)
  },

  data: () => ({
    reportApi,
    dataLoaded: false,
    loading: false,
    reportBtn: false,

    fromDate: null,
    toDate: null,

    reportList: []
  }),

  computed: {
    ...mapGetters('department', ['departmentId', 'departmentName']),

    disableReportBtn() {
      return !(this.fromDate && this.toDate)
    }
  },

  methods: {
    async getReport(type) {
      try {
        let data
        this.loading = true

        const body = {
          departmentId: this.departmentId,
          fromDate: this.fromDate,
          toDate: this.toDate
        }

        switch (type) {
          case 'curator':
            data = await reportApi.getCuratorReportToXls(body)
            data && download(data, 'Отчет по кураторам.xlsx')
            break
          case 'teacher':
            data = await workloadApi.getPedByDeptToXls(body)
            data && download(data, 'Отчет по педагогической нагрузке.xlsx')
        }
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка формирования отчета')
      } finally {
        this.loading = false
      }
    },

    resetTable() {
      this.reportList = []
      this.showPrintBtn = false
    },

    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>

<style scoped>
.base-card .inputs {
  max-width: 600px;
}

.dates {
  display: flex;
}

.date {
  width: 50%;
}
</style>